var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.config.uploadType === 'default')?_c('a-upload',{attrs:{"customRequest":_vm.customRequest,"file-list":_vm.fileList,"show-upload-list":{
        showPreviewIcon: _vm.config.showPreview,
        showRemoveIcon: _vm.config.showRemove,
      },"before-upload":_vm.beforeUpload},on:{"preview":_vm.handlePreviewDown,"change":_vm.handleChange}},[(
          (_vm.config.limitControl && _vm.fileList.length < _vm.config.limitLength) ||
          !_vm.config.limitControl ||
          _vm.config.show
        )?_c('a-button',{staticStyle:{"height":"40px"},attrs:{"loading":_vm.loading,"disabled":_vm.config.disabled || _vm.fileList.length === _vm.config.limitLength}},[(!_vm.loading)?_c('a-icon',{attrs:{"type":"upload"}}):_vm._e(),_vm._v(" 点击上传 ")],1):_vm._e()],1):_vm._e(),(_vm.config.uploadType === 'picture')?_c('a-upload',{staticClass:"avatar-uploader",attrs:{"name":"avatar","list-type":"picture-card","show-upload-list":{
        showPreviewIcon: _vm.config.showPreview,
        showRemoveIcon: _vm.config.showRemove,
      },"customRequest":_vm.customRequest,"before-upload":_vm.beforeUpload,"file-list":_vm.fileList},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[(
          _vm.fileList.length < _vm.config.limitLength &&
          _vm.config.idCard &&
          _vm.config.limitControl
        )?_c('div',{staticClass:"idcard_contain"},[(_vm.config.cardType === 2)?_c('img',{staticClass:"idcard_img",attrs:{"src":require("../assets/idCard.png"),"alt":""}}):_vm._e(),(_vm.config.cardType === 1)?_c('img',{staticClass:"idcard_img",attrs:{"src":require("../assets/idCardT.png"),"alt":""}}):_vm._e(),(_vm.config.cardType === 3)?_c('img',{staticClass:"idcard_img",attrs:{"src":require("../assets/idCardA.png"),"alt":""}}):_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading_contain"},[_c('a-icon',{staticClass:"img_loading",style:({ fontSize: '26px', color: '#1a8af3' }),attrs:{"type":"loading"}})],1):_vm._e()]):_vm._e(),(!_vm.config.limitControl && _vm.config.idCard)?_c('div',{staticClass:"idcard_contain"},[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(_vm._s(_vm.config.text))])],1):_vm._e(),(
          _vm.fileList.length < _vm.config.limitLength &&
          _vm.config.limitControl &&
          !_vm.config.idCard
        )?_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(_vm._s(_vm.config.text))])],1):_vm._e(),(!_vm.config.limitControl && !_vm.config.idCard)?_c('div',[_c('a-icon',{attrs:{"type":_vm.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(_vm._s(_vm.config.text))])],1):_vm._e()]):_vm._e()],1),_c('a-modal',{attrs:{"width":'1000px',"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('div',{staticStyle:{"margin-top":"20px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.download}},[_vm._v(" 下载图片 ")])],1),_c('img',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }