<template>
  <div>
    <div>
      <!-- 文件列表模式 -->
      <a-upload
        v-if="config.uploadType === 'default'"
        :customRequest="customRequest"
        :file-list="fileList"
        @preview="handlePreviewDown"
        :show-upload-list="{
          showPreviewIcon: config.showPreview,
          showRemoveIcon: config.showRemove,
        }"
        :before-upload="beforeUpload"
        @change="handleChange"
      >
        <a-button
          :loading="loading"
          :disabled="config.disabled || fileList.length === config.limitLength"
          v-if="
            (config.limitControl && fileList.length < config.limitLength) ||
            !config.limitControl ||
            config.show
          "
          style="height: 40px"
        >
          <a-icon v-if="!loading" type="upload" /> 点击上传
        </a-button>
      </a-upload>
      <!-- 照片墙模式 -->
      <a-upload
        v-if="config.uploadType === 'picture'"
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="{
          showPreviewIcon: config.showPreview,
          showRemoveIcon: config.showRemove,
        }"
        :customRequest="customRequest"
        @preview="handlePreview"
        :before-upload="beforeUpload"
        :file-list="fileList"
        @change="handleChange"
      >
        <!-- 身份证样式 -->
        <div
          v-if="
            fileList.length < config.limitLength &&
            config.idCard &&
            config.limitControl
          "
          class="idcard_contain"
        >
          <img
            v-if="config.cardType === 2"
            class="idcard_img"
            src="../assets/idCard.png"
            alt=""
          />
          <img
            v-if="config.cardType === 1"
            class="idcard_img"
            src="../assets/idCardT.png"
            alt=""
          />
          <img
            v-if="config.cardType === 3"
            class="idcard_img"
            src="../assets/idCardA.png"
            alt=""
          />
          <div v-if="loading" class="loading_contain">
            <a-icon
              class="img_loading"
              :style="{ fontSize: '26px', color: '#1a8af3' }"
              type="loading"
            />
          </div>
        </div>

        <div
          class="idcard_contain"
          v-if="!config.limitControl && config.idCard"
        >
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">{{ config.text }}</div>
        </div>
        <!-- 身份证样式 -->
        <!-- 替换的图片背景 -->
        <div
          v-if="
            fileList.length < config.limitLength &&
            config.limitControl &&
            !config.idCard
          "
        >
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">{{ config.text }}</div>
        </div>
        <div v-if="!config.limitControl && !config.idCard">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">{{ config.text }}</div>
        </div>
      </a-upload>
    </div>
    <!-- 预览及下载弹框 -->
    <a-modal
      :width="'1000px'"
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <div style="margin-top: 20px">
        <a-button @click="download" type="primary"> 下载图片 </a-button>
      </div>
      <img
        alt="example"
        style="width: 100%; margin-top: 20px"
        :src="previewImage"
      />
    </a-modal>
  </div>
</template>

<script>
import { initObsClient, GetSecretObsClient, getAkAndSk } from "../utils/obs";
export default {
  name: "uploadTag", //OBS上传
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          show: false,
          uploadType: "picture", //上传控件类型
          barrelType: "public", //桶类型
          disabled: false, //默认样式按钮禁用
          showPreview: true, //是否展示预览图标
          showRemove: true, //是否展示删除图标
          size: 10, //限制文件大小，单位M
          limitLength: 1, //限制上传文件数量
          limitControl: true, //是否限制照片墙数量
          fileList: [], //文件列表
          text: "上传图片", //文字内容
          bgWidth: "300px", //背景图片大小
          bgHeight: "120px", //背景图片大小
          idCard: false, //是否启用身份证模式
          cardType: 1, //身份证样式 1 正面 2国徽面 3人手持正面
          limitType: [
            "image/jpeg",
            "image/png",
            "image/jpg",
            "application/pdf",
          ], //限制上传类型
        };
      },
    },
  },
  data() {
    return {
      tempArr: null, //该字段用于组件第一次初始化过后，调用initFilelist方法
      secFileList: [], //临时文件列表，由于直接赋值给fileList会触发照片墙更新，所以需要借用该字段
      loading: false, //上传加载动画
      imageUrl: "", //图片地址
      expires: null, //过期时间
      fileList: [], //控件回显文件列表
      previewImage: "", //图片预览路径
      imageName: "", //图片名称
      previewVisible: false, //控制预览弹框显示
      bgWidth: null,
      bgHeight: null,
    };
  },
  created() {},
  mounted() {
    this.inintUpload();
  },
  updated() {},
  watch: {
    //监测临时文件列表
    tempArr: {
      async handler() {
        this.initFileList();
      },
    },
  },
  methods: {
    //判断是否有临时token和是否过期
    async inintUpload() {
      this.tempArr = this.config.fileList;
      const expires = sessionStorage.getItem("expires_at");
      const securityToken = sessionStorage.getItem("securitytoken");
      //是否有临时token等信息
      if (expires && securityToken) {
        if (new Date(expires).getTime() < Date.now()) {
          //过期，请求临时token
          await this.setSKandAK();
        } else {
          //未过期，初始化
          this.initFileList();
        }
      } else {
        //为满足，请求临时令牌
        await this.setSKandAK();
      }
    },

    //获取临时令牌
    async setSKandAK() {
      let ak = await getAkAndSk(
        "/cloud-platform-backend-facade/front-api/v1/common/object/akAndSk"
      );
      if (ak) {
        //缓存至本地临时仓库
        window.sessionStorage.setItem("securitytoken", ak.securitytoken);
        window.sessionStorage.setItem("secret", ak.secret);
        window.sessionStorage.setItem("access", ak.access);
        window.sessionStorage.setItem("expires_at", ak.expires_at);
        //初始化传进来的文件列表
        this.initFileList();
      } else {
        this.$message.error("临时密钥获取失败");
      }
    },

    //初始化文件列表
    async initFileList() {
      this.secFileList = this.deepCopy(this.config.fileList);

      //判断桶类型
      if (this.config.barrelType !== "public") {
        //备份原本文件列表
        this.loading = true;
        let tempList = this.config.fileList;
        let sk = {
          securitytoken: window.sessionStorage.getItem("securitytoken"),
          secret: window.sessionStorage.getItem("secret"),
          access: window.sessionStorage.getItem("access"),
        };
        //判断传进来的文件是否大于0
        if (tempList.length > 0) {
          //对私有文件url进行处理转换成blob流进行回显

          const handleSecretFile = async (v) => {
        
            if (v.url === v.uid ) {
              //将s对象数组里的该项的name属性作为参数去请求接口
              let tempfile = await GetSecretObsClient(
                v.name,
                "datarun-cloud-sec",
                sk
              );

              v.url = tempfile.fileAddress;
              return v;
            }
          };
          //遍历文件列表每项并进行异步处理
          const lists = [];
          for (let i = 0; i < tempList.length; i++) {
            const item = tempList[i];
            const result = await handleSecretFile(item);
            lists.push(result);
          }
          // this.tempArr = tempList;
          this.fileList = this.deepCopy(tempList);
          this.loading = false;
        } else {
          this.fileList = [];
          this.loading = false;
        }
      } else {
        this.fileList = this.config.fileList;
        this.loading = false;
      }
    },
    //深拷贝
    deepCopy(source) {
      let _source = JSON.stringify(source);
      return JSON.parse(_source);
    },

    //非照片墙模式下的文件列表点击下载
    handlePreviewDown(file) {
      let fileName = file.name;
      let link = document.createElement("a");
      fetch(file.url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
        });
    },
    //预览按钮事件
    handlePreview(file) {
      if (file.url) {
        this.imageUrl = file.url;
        this.imageName = file.name;
      }
      this.previewImage = this.imageUrl;
      this.previewVisible = true;
    },
    //弹框内下载按钮事件
    download() {
      let fileName = this.imageName;
      let link = document.createElement("a");
      fetch(this.imageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
        });
    },
    //关闭弹窗
    handleCancel() {
      this.previewVisible = false;
    },
    //上传文件obs方法
    async initOssClient(file) {
      let sk = {
        securitytoken: window.sessionStorage.getItem("securitytoken"),
        secret: window.sessionStorage.getItem("secret"),
        access: window.sessionStorage.getItem("access"),
      };
      // 上传的返回结果
      const fileResult = await initObsClient(
        file,
        this.config.barrelType === "public"
          ? "datarun-cloud-pub"
          : "datarun-cloud-sec",
        sk
      );
      if (fileResult) {
        this.loading = false;
        if (this.config.barrelType === "public") {
          this.imageUrl = fileResult.fileAddress;
          this.fileList.push({
            name: fileResult.fileName,
            uid: this.imageUrl,
            status: "done",
            url: this.imageUrl,
          });
          console.log('1', this.imageUrl)
          this.$emit("getImageUrl", this.imageUrl, this.fileList);
        } else {
          this.imageUrl = fileResult.fileAddress;
          // let s = [];
          this.secFileList.push({
            // s.push({
            name: fileResult.fileName,
            uid: this.imageUrl,
            status: "done",
            url: this.imageUrl,
          });
          //赋值，然后根据监测的tempArr,触发initFileList，实现在不经过mouted后的初始化
          this.tempArr = this.secFileList;
          if (this.config.barrelType === "public") {
            console.log('2', this.imageUrl)
            this.$emit("getImageUrl", this.imageUrl, this.tempArr);
          } else {
            console.log('3', this.imageUrl)
            this.$emit("getImageUrl", fileResult.fileName, this.tempArr);
          }
        }
      } else {
        this.$message.error("上传失败");
        this.loading = false;
      }
    },

    //读取文件流
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    //自定义上传规则
    customRequest(action) {
      this.loading = true;
      const file = action.file;
      this.initOssClient(file);
    },

    //上传文件状态变化
    handleChange(info) {
      if (info.file.status === "removed") {
        if (this.config.barrelType === "secret") {
          this.secFileList = this.secFileList.filter((v) => {
            return v.uid != info.file.uid;
          });
          // this.fileList = this.deepCopy(this.secFileList);
          console.log('4', this.imageUrl)
          this.$emit("getImageUrl", null, this.secFileList);
          //深拷贝，防止两者关联引发其他bug
          this.tempArr = this.deepCopy(this.secFileList); //触发初始化，
        } else {
          this.fileList = info.fileList;
          console.log('5', this.imageUrl)
          this.$emit("getImageUrl", null, this.fileList);
        }
        this.loading = false;
        return;
      }
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        console.log("done");
      }
      if (info.file.status === "error") {
        this.loading = false;
        this.$message.error("上传失败");
        return;
      }
    },

    //上传文件前校验
    beforeUpload(file) {
      let isJpgOrPng = false;
      if (!this.config.limitType) {
        this.config.limitType = ["image/jpeg", "image/png", "image/jpg"];
      }
      isJpgOrPng = this.config.limitType.includes(file.type);
      let s = "";
      if (!isJpgOrPng) {
        this.config.limitType.forEach((v, index) => {
          let b = v.substr(v.lastIndexOf("/") + 1);
          if (index != this.config.limitType.length - 1) {
            s = s + b + "、";
          } else {
            s = s + b;
          }
        });
        this.$message.error(`仅支持:${s}格式文件`);
      }
      const isLt2M = file.size / 1024 / 1024 < this.config.size;
      if (!isLt2M) {
        this.$message.error(`单个文件不超过${this.config.size}M`);
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
/deep/.ant-upload-select-picture-card i {
  font-size: 20px;
  color: #999;
}

/deep/.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.idcard_contain {
  width: 240px;
  height: 140px;
  position: relative;
}
.idcard_img {
  width: 240px;
  height: 140px;
}
.img_loading {
  position: absolute;
  top: 42%;
  left: 44%;
  // font-size: 26px;
  // color: #1a8af3;
  z-index: 2000;
}
.loading_contain {
  position: absolute;
  top: 0px;
  width: 240px;
  height: 140px;
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.158);
}
</style>